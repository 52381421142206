import { useContext, useEffect, useState } from "react";
import { DatePicker, Select, Table, Row, Col, Tabs, Tag, Input } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { Context } from "../../context";
import { Fetch } from "../../fetch";
import { convertIdToKey, handleExportClick } from "../../utils";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { Column } = Table;
const dateFormat = "YYYY-MM-DD";
const { TabPane } = Tabs;

const UserReports = () => {
    const { token, apiUrl } = useContext(Context);

    const [activeTab, setActiveTab] = useState("1");
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [filters, setFilters] = useState({
        date: moment().format('YYYY-MM-DD'),
        plan: null,
        paid: null,
        currency: null,
        model: "SUBSCRIPTION",
        country: null,
        platform: null,
        singleDate: moment().startOf("month"),
    });

    const [plans, setPlans] = useState([]);
    const [users, setUsers] = useState([]);
    const [movies, setMovies] = useState([]);
    const [series, setSeries] = useState([]);

    const [data7, setData7] = useState([]);
    const [data1, setData1] = useState();
    const [data3, setData3] = useState();
    const [data4, setData4] = useState();

    const updateData = () => {
        if (activeTab === "1") {
            Fetch(
                `${apiUrl}/reports/user_subscriptions?query_date=${filters.date}`,
                token,
                (result) => {
                    setData1(result.data)
                }
            )
        } else if (activeTab === "6") {
            Fetch(
                `${apiUrl}/reports/revenue?query_date=${filters.date}`,
                token,
                (result) => {
                    setData7(result.data)
                }
            )
        } else if (activeTab == "4") {
            Fetch(
                `${apiUrl}/reports/user_metrics?query_date=${filters.date}`,
                token,
                (result) => {
                    setData4([result.data])
                }
            )
        }
    }

    useEffect(() => {
        setData1([])
        setData3([])
        setData4([])
        setData7([])
        setFilters({
            ...filters,
            date: moment().format('YYYY-MM-DD'),
        })

        updateData()
    }, [apiUrl, token, activeTab])

    useEffect(() => {
        updateData()
    }, [apiUrl, token, filters])

    const customfilters = (
        <>
            {" "}
            <Row style={{ marginBottom: "20px" }} align={"middle"}>
                {["2"].includes(activeTab) && (
                    <Col span={6}>
                        <DatePicker.RangePicker
                            style={{ width: "100%" }}
                            defaultValue={[
                                moment(filters.date[0], dateFormat),
                                moment(filters.date[1], dateFormat),
                            ]}
                            onChange={(value, dateString) =>
                                setFilters({
                                    ...filters,
                                    date: dateString ? dateString : [null, null],
                                })
                            }
                            format={dateFormat}
                        />
                    </Col>
                )}
                {["1", "5", "4", "3", "6"].includes(activeTab) && (
                    <Col span={6}>
                        <DatePicker
                            style={{ width: "100%" }}
                            onChange={(value, dateString) =>
                                setFilters({
                                    ...filters,
                                    date: dateString ? dateString : null,
                                })
                            }
                            format={dateFormat}
                            defaultValue={dayjs()}
                        />
                    </Col>
                )}
                {["3"].includes(activeTab) && (
                    <>
                        <Col span={4}>
                            <Select
                                style={{ width: "100%" }}
                                placeholder={"Filter by Platform"}
                                allowClear
                                onChange={(value) =>
                                    setFilters({ ...filters, platform: value })
                                }
                            >
                                {["android", "ios", "web", "android_tv", "ios_tv"].map(
                                    (code) => (
                                        <Select.Option key={code} value={code}>
                                            {code}
                                        </Select.Option>
                                    )
                                )}
                            </Select>
                        </Col>
                        <Col span={4}>
                            <Select
                                style={{ width: "100%" }}
                                placeholder={"Filter by Country"}
                                allowClear
                                onChange={(value) => setFilters({ ...filters, country: value })}
                            >
                                {["INDIA", "USA", "RUSSIA"].map((code) => (
                                    <Select.Option key={code} value={code}>
                                        {code}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </>
                )}
            </Row>
        </>
    );

    const extractName = (record) => {
        for (const u of users) {
            if (u.key === record.userId) return u.name;
        }
    }

    const extractContentName = (record) => {
        for (const m of movies) {
            if (m.key === record.content)
                return m.title
        }
        for (const s of series) {
            if (s.key === record.content)
                return s.title
        }
    }

    return (
        <div className="manage-orders-wrapper">
            <Tabs
                defaultActiveKey="1"
                activeKey={activeTab}
                onChange={(v) => setActiveTab(v)}
            >
                <TabPane tab="Subscriptions" key="1">
                    {customfilters}
                    <Table dataSource={data1}>
                        <Column
                            title="User ID"
                            dataIndex="user"
                            key="user"
                            align="center"
                        />
                        <Column
                            title="Source/Medium"
                            dataIndex="source"
                            key="source"
                            align="center"
                            render={() => 'NA'}
                        />
                        <Column
                            title="Content clicked for Purchase"
                            dataIndex="content"
                            key="content"
                            align="center"
                            //render={(_, record) => extractContentName(record)}
                            render={() => 'NA'}
                        />
                    </Table>
                </TabPane>
                <TabPane tab="Revenue Reports" key="6">
                    {customfilters}
                    <Table dataSource={data7}>
                        <Column
                            title="Subscription Revenue"
                            dataIndex="subscription_revenue"
                            key="subscription_revenue"
                            align="center"
                        />
                        <Column
                            title="Renewal Revenue"
                            dataIndex="renewal_revenue"
                            key="renewal_revenue"
                            align="center"
                        />
                        <Column
                            title="Total Revenue"
                            dataIndex="total_revenue"
                            key="total_revenue"
                            align="center"
                        />
                        <Column title="ARPU" dataIndex="arpu" key="arpu" align="center" />
                    </Table>
                </TabPane>
                {/* <TabPane tab="Active Users" key="3">
          {customfilters}
          <Table
            dataSource={data3}
            pagination={false}
            //onChange={handleTableDataChange}
          >
            <Column title="DAU" dataIndex="DAU" key="DAU" align="center" />
            <Column title="WAU" dataIndex="WAU" key="WAU" align="center" />
            <Column title="MAU" dataIndex="MAU" key="MAU" align="center" />
            <Column title="UU" dataIndex="UU" key="UU" align="center" />
            <Column
              title="Video views"
              dataIndex="VV"
              key="VV"
              align="center"
            />
            <Column
              title="ER = DAU/MAU"
              dataIndex="ER"
              key="ER"
              align="center"
            />
          </Table>
        </TabPane> */}
                <TabPane tab="New vs Repeat Users" key="4">
                    {customfilters}
                    <Table
                        dataSource={data4}
                        pagination={false}
                    //onChange={handleTableDataChange}
                    >
                        <Column
                            title="New User Count"
                            dataIndex="new"
                            key="new"
                            align="center"
                        />
                        <Column
                            title="Repeat User Count"
                            dataIndex="returning"
                            key="returning"
                            align="center"
                        />
                    </Table>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default UserReports;