import { useContext, useEffect, useState } from "react";
import { DatePicker, Select, Table, Row, Col, Tabs, Tag, Input } from "antd";
import moment from "moment";
import { Context } from "../../context";
import { Fetch } from "../../fetch";
import { convertIdToKey, handleExportClick } from "../../utils";

const { Column } = Table;
const { TabPane } = Tabs;
const { Option } = Select;


const LikeReports = () => {
  const { token, apiUrl } = useContext(Context);

  const [activeTab, setActiveTab] = useState("1");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [movies, setMovies] = useState([]);
  const [series, setSeries] = useState([]);
  const [moviesMaster, setMoviesMaster] = useState([]);
  const [seriesMaster, setSeriesMaster] = useState([]);

  useEffect(() => {
    Fetch(`${apiUrl}/movies`, token, (result) => {
      const movies = convertIdToKey(result.movies)
      setMovies(movies)
    })

    Fetch(`${apiUrl}/series`, token, (result) => {
      const series = convertIdToKey(result.series)
      setSeries(series)
    })
  }, [apiUrl, token])

  useEffect(() => {
    if (!movies && !series) {
      return
    }

    Fetch(`${apiUrl}/reports/likes_dislikes`, token, result => {
      const rawData = result

      const data = []
      movies.forEach(m => {
        if (m.key in rawData['movie_likes'] || m.key in rawData['movie_dislikes']) {
          data.push({key: m['key'], likes: rawData['movie_likes'][m.key] ? rawData['movie_likes'][m.key] : 0, dislikes: rawData['movie_dislikes'][m.key] ? rawData['movie_dislikes'][m.key] : 0})
        }
      })
      series.forEach(m => {
        if (m.key in rawData['series_likes'] || m.key in rawData['series_dislikes']) {
          data.push({key: m['key'], likes: rawData['series_likes'][m.key] ? rawData['series_likes'][m.key] : 0, dislikes: rawData['series_dislikes'][m.key] ? rawData['series_dislikes'][m.key] : 0})
        }
      })

      setData(data.sort((a, b) => (b.likes + b.dislikes) - (a.likes + a.dislikes)))
    })
  }, [apiUrl, token, movies, series])

  const extractName = (record) => {
    for (const m of movies) {
      if (m.key === record.key)
        return m.title
    }
    for (const s of series) {
      if (s.key === record.key)
        return s.title
    }
  }

  return (
    <div className="manage-orders-wrapper">
      <>
        <Tabs
          defaultActiveKey="1"
          activeKey={activeTab}
          onChange={(v) => setActiveTab(v)}
        >
          <TabPane tab="Likes Report" key="1">
            <Row style={{ marginBottom: "20px" }} align={"middle"}>
              <Col span={4}>
                {/* <Select
                  placeholder="Search by Content"
                  showSearch
                  allowClear
                  onChange={() => {}}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {fullData.map((s) => (
                    <Option value={s.key}>{s.title}</Option>
                  ))}
                </Select> */}
              </Col>
            </Row>
            <Table
              dataSource={data}
              pagination={false}
            >
              <Column
                title="Content Title"
                dataIndex="content"
                key="content"
                align="center"
                render={(_, record) => extractName(record)}
              />
              <Column
                title="Like count"
                dataIndex="likes"
                key="likes"
                align="center"
                render={(_, v) => v.likes ? v.likes : 0}
              />
              <Column
                title="Dislike count"
                dataIndex="dislikes"
                key="dislikes"
                align="center"
                render={(_, v) => v.dislikes ? v.dislikes : 0}
              />
            </Table>
          </TabPane>
        </Tabs>
      </>
    </div>
  );
};

export default LikeReports;