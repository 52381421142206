import {
  Form,
  Input,
  InputNumber,
  Button,
  Tabs,
  Radio,
  Select,
  Card,
  DatePicker,
  Upload,
  notification,
  message,
  Row,
  Col,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";

import "./styles.css";
import { Context } from "../../context";
import { Fetch, Post } from "../../fetch";
import { convertIdToKey, countryCodes } from "../../utils";
import MultiCurrencyInput from "../MultiCurrencyInput/index";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const AddContent = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [contentType, setContentType] = useState("movie");
  const [availability, setAvailability] = useState("perpetual");
  const [monetizationModel, setMonetizationModel] = useState("");
  const [series, setSeries] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [listType, setListType] = useState('allow_all')
  const [allowList, setAllowList] = useState([]);

  const { token, apiUrl, superAdmin } = useContext(Context);

  const [contentInfoForm] = Form.useForm();
  const [castCrewAvailabilityForm] = Form.useForm();
  const [posterBannersForm] = Form.useForm();
  const [monetizationStreamingForm] = Form.useForm();
  const [geoAccessForm] = Form.useForm();

  const [actors, setActors] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [producers, setProducers] = useState([]);
  const [singers, setSingers] = useState([]);
  const [lyricists, setLyricists] = useState([]);
  const [genres, setGenres] = useState([]);
  const [languages, setLanguages] = useState([]);

  const [cardImageLoading, setCardImageLoading] = useState(false);
  const [detailImageLoading, setDetailImageLoading] = useState(false);
  const [trailerImageLoading, setTrailerImageLoading] = useState(false);
  const [cardImageUrl, setCardImageUrl] = useState();
  const [detailImageUrl, setDetailImageUrl] = useState();
  const [trailerImageUrl, setTrailerImageUrl] = useState();

  const cardImageUploadButton =
    contentType === "movie" || contentType === "song" ? (
      <div>
        {cardImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload (300x400)</div>
      </div>
    ) : (
      <div>
        {cardImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload (450x250)</div>
      </div>
    );

  const detailImageUploadButton = (
    <div>
      {detailImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload (1500x500)</div>
    </div>
  );

  const trailerImageUploadButton = (
    <div>
      {trailerImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload (512x288)</div>
    </div>
  );

  const beforeImageUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Only JPG and PNG files are allowed");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error("Image must be smaller than 1M");
    }

    return isJpgOrPng && isLt2M;
  };

  const handleCardImageUploadStatusChange = (info) => {
    if (info.file.status === "uploading") {
      setCardImageLoading(true);
      return;
    }

    if (info.file.status === "done") {
      setCardImageLoading(false);
      setCardImageUrl(info.file.response.url + '/desktopCard');
      posterBannersForm.setFieldsValue({ cardImage: info.file.response.url });
    }
  };

  const handleDetailImageUploadStatusChange = (info) => {
    if (info.file.status === "uploading") {
      setDetailImageLoading(true);
      return;
    }

    if (info.file.status === "done") {
      setDetailImageLoading(false);
      setDetailImageUrl(info.file.response.url + '/desktopDetail');
      posterBannersForm.setFieldsValue({ detailImage: info.file.response.url });
    }
  };

  const handleTrailerImageUploadStatusChange = (info) => {
    if (info.file.status === "uploading") {
      setTrailerImageLoading(true);
      return;
    }

    if (info.file.status === "done") {
      setTrailerImageLoading(false);
      setTrailerImageUrl(info.file.response.url + 'desktopTrailer');
      posterBannersForm.setFieldsValue({
        trailerImage: info.file.response.url,
      });
    }
  };

  useEffect(() => {
    Fetch(`${apiUrl}/artists`, token, (result) => {
      setActors(result.artists);
    });

    Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
      setDirectors(result.directors);
      setProducers(result.producers);
      setSingers(result.singers);
      setLyricists(result.lyricists);
    });

    Fetch(`${apiUrl}/genres`, token, (result) => {
      setGenres(result.genres);
    });

    Fetch(`${apiUrl}/languages`, token, (result) => {
      setLanguages(result.languages);
    });

    Fetch(`${apiUrl}/series`, token, (result) => {
      const series = convertIdToKey(result.series);
      setSeries(series);
    });

    Fetch(`${apiUrl}/songs`, token, (result) => {
      const albums = convertIdToKey(result.songs);
      setAlbums(albums);
    });
  }, [apiUrl, token]);

  const onFinish = async (e) => {
    e.preventDefault();

    let contentInfo, castCrewAvailability, posterBanners, monetizationStreaming, geoAccess;
    let valid = true;

    try {
      contentInfo = await contentInfoForm.validateFields();
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Content Info Error",
        description: "Required fields are incorrect/missing",
      });
      valid = false;
    }

    try {
      castCrewAvailability = await castCrewAvailabilityForm.validateFields();
      console.log(castCrewAvailability);
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Cast & Crew Error",
        description: "Required fields are incorrect/missing",
      });
      valid = false;
    }

    try {
      posterBanners = await posterBannersForm.validateFields();
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Posters & Banner Error",
        description: "Required fields are incorrect/missing",
      });
      valid = false;
    }

    try {
      geoAccess = await geoAccessForm.validateFields();
      if (listType === 'allow') {
        for (const i in countryCodes) {
          if (geoAccess.countryMap.includes(i)) allowList.push(i)
        }
        console.log(allowList)
      }
      if (listType === 'disallow') {
        for (const i in countryCodes) {
          if (!geoAccess.countryMap.includes(i)) allowList.push(i)
        }
        console.log(allowList)
      }
      if (listType === 'allow_all') {
        for (const i in countryCodes) {
          allowList.push(i)
        }
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Geo Access Error",
        description: "Required fields are incorrect/missing",
      });
      valid = false;
    }

    try {
      monetizationStreaming = await monetizationStreamingForm.validateFields();
      if (monetizationStreaming.startDate)
        monetizationStreaming["startDate"] =
          monetizationStreaming.startDate.toISOString(true);
      if (monetizationStreaming.endDate)
        monetizationStreaming["endDate"] =
          monetizationStreaming.endDate.toISOString(true);
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Monetization & Stream Error",
        description: "Required fields are incorrect/missing",
      });
      valid = false;
    }

    if (valid && contentType === "movie") {
      Post(
        `${apiUrl}/movies`,
        token,
        {
          ...contentInfo,
          ...castCrewAvailability,
          ...posterBanners,
          ...monetizationStreaming,
          allowedCountries: allowList,
          listType: listType,
        },
        (result) => {
          if (result.success) {
            contentInfoForm.resetFields();
            castCrewAvailabilityForm.resetFields();
            posterBannersForm.resetFields();
            monetizationStreamingForm.resetFields();
            setCardImageUrl("");
            setDetailImageUrl("");
            setActiveTab("1");
            setAllowList([]);

            notification.success({
              message: "Success",
              description: "Content created",
            });
          }
        },
        () => {
          notification.error({
            message: "Error",
            description: "Please check console for more details",
          });
        }
      );
    } else if (valid && contentType === "series") {
      Post(
        `${apiUrl}/series/${contentInfo.series}/episodes`,
        token,
        {
          ...contentInfo,
          ...castCrewAvailability,
          ...posterBanners,
          ...monetizationStreaming,
        },
        (result) => {
          if (result.success) {
            contentInfoForm.resetFields();
            castCrewAvailabilityForm.resetFields();
            posterBannersForm.resetFields();
            monetizationStreamingForm.resetFields();
            setCardImageUrl("");
            setDetailImageUrl("");
            setActiveTab("1");

            notification.success({
              message: "Success",
              description: "Content created",
            });
          }
        },
        () => {
          notification.error({
            message: "Error",
            description: "Please check console for more details",
          });
        }
      );
    } else if (valid && contentType === "song") {
      Post(
        `${apiUrl}/songs`,
        token,
        {
          ...contentInfo,
          ...castCrewAvailability,
          ...posterBanners,
          ...monetizationStreaming,
          allowedCountries: allowList,
          listType: listType,
        },
        (result) => {
          if (result.success) {
            contentInfoForm.resetFields();
            castCrewAvailabilityForm.resetFields();
            posterBannersForm.resetFields();
            monetizationStreamingForm.resetFields();
            setCardImageUrl("");
            setDetailImageUrl("");
            setActiveTab("1");
            setAllowList([]);

            notification.success({
              message: "Success",
              description: "Content created",
            });
          }
        },
        () => {
          notification.error({
            message: "Error",
            description: "Please check console for more details",
          });
        }
      );
    }
  };

  return (
    <div className="add-content-wrapper">
      <h3>Add Content</h3>
      <Tabs
        activeKey={activeTab}
        onChange={(tab) => setActiveTab(tab)}
        className="add-content-tabs"
      >
        <TabPane tab="Content Information" key="1">
          <Form layout="vertical" form={contentInfoForm}>
            <Radio.Group
              className="content-type-option"
              value={contentType}
              onChange={(e) => setContentType(e.target.value)}
            >
              <Radio value="movie">Movie</Radio>
              <Radio value="series">Episode</Radio>
              <Radio value="song">Song</Radio>
            </Radio.Group>
            {contentType === "movie" && (
              <>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true }]}
                >
                  <Input size="middle" />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true }]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  name="language"
                  label="Language"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Choose a language"
                    mode="multiple"
                    showSearch
                  >
                    {languages.map((language) => (
                      <Option value={language.name}>{language.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="genre"
                  label="Genre / Category"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Choose a genre"
                    mode="multiple"
                    showSearch
                  >
                    {genres.map((genre) => (
                      <Option value={genre.name}>{genre.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="maturity"
                  label="Maturity"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Choose maturity">
                    <Option value="U">U (Universal)</Option>
                    <Option value="U/A 7+">
                      U/A 7+ (For Ages 7 and Above)
                    </Option>
                    <Option value="U/A 13+">
                      U/A 13+ (For Ages 13 and Above)
                    </Option>
                    <Option value="U/A 16+">
                      U/A 16+ (For Ages 16 and Above)
                    </Option>
                    <Option value="A">A (Adults - Ages 18 and Above)</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="year"
                  label="Year"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="oldUrl"
                  label="Old URL"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            {contentType === "series" && series.length > 0 && (
              <>
                <Form.Item
                  name="series"
                  label="Series"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select Series">
                    {series.map((s) => (
                      <Option value={s.key}>{s.title}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="season"
                  label="Season"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={1} max={99} />
                </Form.Item>
                <Form.Item
                  name="number"
                  label="Episode #"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={1} max={99} />
                </Form.Item>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true }]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  name="oldUrl"
                  label="Old URL"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            {contentType === "song" && (
              <>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="language"
                  label="Language"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Choose a language" showSearch>
                    {languages.map((language) => (
                      <Option value={language.name}>{language.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="genre"
                  label="Genre / Category"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Choose a genre"
                    mode="multiple"
                    showSearch
                  >
                    {genres.map((genre) => (
                      <Option value={genre.name}>{genre.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="maturity"
                  label="Maturity"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Choose maturity">
                    <Option value="U">U (Universal)</Option>
                    <Option value="U/A 7+">
                      U/A 7+ (For Ages 7 and Above)
                    </Option>
                    <Option value="U/A 13+">
                      U/A 13+ (For Ages 13 and Above)
                    </Option>
                    <Option value="U/A 16+">
                      U/A 16+ (For Ages 16 and Above)
                    </Option>
                    <Option value="A">A (Adults - Ages 18 and Above)</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="year"
                  label="Year"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            {["movie"].includes(contentType) && (
              <>
                <Form.Item
                  name="slug"
                  label="Slug in Url"
                  rules={[
                    { required: true },
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9\-]+$/),
                      message: "Please enter alphanumeric or - only",
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    placeholder="Enter only alphanumeric or -"
                  />
                </Form.Item>
              </>
            )}
          </Form>
          <Button className="next-btn" onClick={() => setActiveTab("2")}>
            Next
          </Button>
        </TabPane>
        <TabPane tab="Cast, Crew &amp; Availability" key="2">

          <Form form={castCrewAvailabilityForm} style={{ width: '100%', }} >
            <Card title="Cast &amp; Crew" className="form-card">
              <Form.Item
                name="actors"
                label="Actors"
                rules={[{ required: contentType !== "series" }]}
                style={{ width: '100%' }}
              >
                <Select
                  showSearch
                  mode="multiple"
                  className="form-card-selector"
                  style={{ width: '100%' }}
                >
                  {actors.map((actor) => (
                    <Option key={actor.key} value={actor.name}>
                      {actor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {contentType === "song" && (
                <>
                  <Form.Item
                    name="singers"
                    label="Singers"
                    rules={[{ required: true }]}
                    style={{ width: '100%' }}
                  >
                    <Select showSearch mode="multiple" style={{ width: '100%' }}>
                      {singers.map((singer) => (
                        <Option key={singer.key} value={singer.name}>
                          {singer.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="lyricists"
                    label="Lyricists"
                    rules={[{ required: true }]}
                  >
                    <Select showSearch mode="multiple" style={{ width: '100%' }}>
                      {lyricists.map((lyricist) => (
                        <Option key={lyricist.key} value={lyricist.name}>
                          {lyricist.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="directors"
                label="Directors"
                rules={[{ required: contentType === "movie" }]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  className="form-card-selector"
                  style={{ width: '100%' }}
                >
                  {directors.map((director) => (
                    <Option key={director.key} value={director.name}>
                      {director.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="producers"
                label="Producers"
                rules={[{ required: contentType === "movie" }]}
              >
                <Select showSearch mode="multiple" style={{ width: '100%' }}>
                  {producers.map((producer) => (
                    <Option key={producer.key} value={producer.name}>
                      {producer.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
            {superAdmin && (
              <Card title="Availability" className="form-card">
                <Form.Item
                  name="availability"
                  label="Availability"
                  rules={[{ required: contentType === "movie" }]}
                >
                  <Radio.Group
                    onChange={(e) => setAvailability(e.target.value)}
                    disabled={contentType === "series"}
                  >
                    <Radio value="perpetual">Perpetual</Radio>
                    <Radio value="restricted">Restricted</Radio>
                    <Radio value="unpublished">Unpublished</Radio>
                  </Radio.Group>
                </Form.Item>
                {availability === "restricted" && (
                  <>
                    <Form.Item
                      name="startDate"
                      label="Start Date"
                      className="form-item-selector"
                      rules={[{ required: true }]}
                    >
                      <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                    </Form.Item>
                    <Form.Item
                      name="endDate"
                      label="End Date"
                      className="form-item-selector"
                      format="YYYY-MM-DD HH:mm:ss"
                    >
                      <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                    </Form.Item>
                  </>
                )}
              </Card>
            )}
          </Form>
          <Button className="next-btn" onClick={() => setActiveTab("3")}>
            Next
          </Button>
        </TabPane>
        <TabPane tab="Poster &amp; Banners" key="3">
          <Form form={posterBannersForm}>
            <Form.Item
              name="cardImage"
              label="Card Image"
              rules={[{ required: true }]}
            >
              <Upload
                listType="picture-card"
                showUploadList={false}
                action={`${apiUrl}/uploads/cloudflare`}
                headers={{
                  authorization: `Bearer ${token}`,
                }}
                beforeUpload={beforeImageUpload}
                onChange={handleCardImageUploadStatusChange}
              >
                {cardImageUrl ? (
                  <img src={cardImageUrl} style={{ width: "100%" }} />
                ) : (
                  cardImageUploadButton
                )}
              </Upload>
            </Form.Item>
            {contentType === "movie" && (
              <Form.Item
                name="detailImage"
                label="Detail Image"
                rules={[{ required: true }]}
              >
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  action={`${apiUrl}/uploads/cloudflare`}
                  headers={{
                    authorization: `Bearer ${token}`,
                  }}
                  beforeUpload={beforeImageUpload}
                  onChange={handleDetailImageUploadStatusChange}
                >
                  {detailImageUrl ? (
                    <img src={detailImageUrl} style={{ width: "100%" }} />
                  ) : (
                    detailImageUploadButton
                  )}
                </Upload>
              </Form.Item>
            )}
            {contentType === "movie" && (
              <Form.Item
                name="trailerImage"
                label="Trailer Image"
                rules={[{ required: true }]}
              >
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  action={`${apiUrl}/uploads/cloudflare`}
                  headers={{
                    authorization: `Bearer ${token}`,
                  }}
                  beforeUpload={beforeImageUpload}
                  onChange={handleTrailerImageUploadStatusChange}
                >
                  {trailerImageUrl ? (
                    <img src={trailerImageUrl + '/public'} style={{ width: "100%" }} />
                  ) : (
                    trailerImageUploadButton
                  )}
                </Upload>
              </Form.Item>
            )}
            {contentType === "song" && (
              <Form.Item
                name="trailerImage"
                label="Trailer Image"
                rules={[{ required: true }]}
              >
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  action={`${apiUrl}/uploads/cloudflare`}
                  headers={{
                    authorization: `Bearer ${token}`,
                  }}
                  beforeUpload={beforeImageUpload}
                  onChange={handleTrailerImageUploadStatusChange}
                >
                  {trailerImageUrl ? (
                    <img src={trailerImageUrl + '/public'} style={{ width: "100%" }} />
                  ) : (
                    trailerImageUploadButton
                  )}
                </Upload>
              </Form.Item>
            )}
          </Form>
          <Button className="next-btn" onClick={() => setActiveTab("4")}>
            Next
          </Button>
        </TabPane>
        <TabPane tab="Geo Access" key="4">
          <Form layout="vertical" form={geoAccessForm}>
            <Form.Item name='listType' label={'Content access'} rules={[{ 'required': true }]}>
              <Radio.Group
                className="content-type-option"
                value={listType}
                onChange={(e) => setListType(e.target.value)}
                defaultValue={'allow_all'}
              >
                <Radio value="allow_all">Allow Globally</Radio>
                <Radio value="allow">Allow List</Radio>
                <Radio value="disallow">Deny List</Radio>
              </Radio.Group>
            </Form.Item>

            {['allow', 'disallow'].includes(listType) && (
              <Form.Item
                name={`countryMap`}
                label={'Countries'}
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <Select
                  showSearch
                  mode="multiple"
                  className="form-card-selector"
                  style={{ width: '100%' }}
                >
                  {Object.entries(countryCodes).map(([code, name]) => (
                    <Option key={code} value={code} >
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>)}
          </Form>
          <Button className="next-btn" onClick={() => setActiveTab("5")}>
            Next
          </Button>
        </TabPane>
        <TabPane tab="Monetization &amp; Stream" key="5">
          <Form form={monetizationStreamingForm}>
            <Card title="Monetization" className="form-card">
              <Form.Item label="Model" name="model">
                <Radio.Group
                  onChange={(e) => setMonetizationModel(e.target.value)}
                  disabled={contentType === "series"}
                >
                  <Radio value="ticket" disabled={contentType === "song"}>
                    Ticket
                  </Radio>
                  <Radio value="subscription">Subscription</Radio>
                  <Radio value="free">Free</Radio>
                </Radio.Group>
              </Form.Item>
              {monetizationModel === "ticket" && (
                <>
                  <Form.Item
                    name="streamPeriod"
                    label="Streaming Period"
                    className="form-item-selector"
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      min={1}
                      max={30}
                      placeholder="Enter number of days (1-30)"
                    />
                  </Form.Item>
                  <Form.Item
                    name="subscriberPrice"
                    label="Price for Subscribers"
                    className="form-item-selector"
                    rules={[{ required: true }]}
                  >
                    <MultiCurrencyInput
                      form={monetizationStreamingForm}
                      fieldName={"subscriberPrice"}
                    ></MultiCurrencyInput>
                  </Form.Item>
                  <Form.Item
                    name="nonSubscriberPrice"
                    label="Price for Non Subscribers"
                    className="form-item-selector"
                    rules={[{ required: true }]}
                  >
                    <MultiCurrencyInput
                      form={monetizationStreamingForm}
                      fieldName={"nonSubscriberPrice"}
                    ></MultiCurrencyInput>
                  </Form.Item>
                </>
              )}
            </Card>
            <Card title="Stream" className="form-card">
              <Form.Item
                name="duration"
                label="Duration"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder="Enter duration in seconds"
                  min={1}
                  max={18000}
                />
              </Form.Item>
              <Form.Item
                name="skipIntroStart"
                label="Skip Intro Start"
                className="form-item-selector"
              >
                <InputNumber
                  placeholder="Enter start time in seconds"
                  disabled={contentType === "song"}
                  min={1}
                  max={18000}
                />
              </Form.Item>
              <Form.Item
                name="skipIntroEnd"
                label="Skip Intro End"
                className="form-item-selector"
              >
                <InputNumber
                  placeholder="Enter end time in seconds"
                  disabled={contentType === "song"}
                  min={1}
                  max={18000}
                />
              </Form.Item>
            </Card>
            <Button onClick={onFinish} className="finish-btn">
              Finish
            </Button>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AddContent;
