import { useContext, useEffect, useState } from "react";
import { DatePicker, Select, Table, Row, Col, Tabs, Tag, Input } from "antd";
import moment from "moment";
import { Context } from "../../context";
import { Fetch } from "../../fetch";
import { convertIdToKey, handleExportClick } from "../../utils";

const { Column } = Table;
const dateFormat = "YYYY-MM-DD";
const { TabPane } = Tabs;

const ChurnReports = () => {
  const { token, apiUrl } = useContext(Context);

  const [activeTab, setActiveTab] = useState("1");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [filters, setFilters] = useState({
    date: [moment().startOf("month"), moment().endOf("month")],
    plan: null,
    paid: null,
    currency: null,
    model: null,
    country: null,
    platform: null,
    content: null,
  });
  const [plans, setPlans] = useState([]);
  const [churn, setChurn] = useState([]);

  const handleTableDataChange = (page) => {
    let f = filters;
    let planstring = f.plan ? `plan=${f.plan}` : ``;
    let sdate = moment(f.date[0]).isValid()
      ? moment(f.date[0]).format(dateFormat)
      : null;
    let edate = moment(f.date[1]).isValid()
      ? moment(f.date[1]).format(dateFormat)
      : null;
    let datestring =
      sdate && edate ? `start_date=${sdate}&end_date=${edate}` : ``;
    let paidstring = f.paid ? `paid=${true}` : ``;
    let currencystring = f.currency ? `currency=${f.currency}` : ``;
    let fstring = `${planstring}&${datestring}&${paidstring}&${currencystring}`;
  };

  useEffect(() => {
    handleTableDataChange(pagination);
    Fetch(
      `${apiUrl}/reports/churn_reports`,
      token,
      (result) => {
        setChurn(result.data)
      }
    )
  }, [apiUrl, token, filters]);

  const customfilters = (
    <>
      {" "}
      <Row style={{ marginBottom: "20px" }} align={"middle"}>
        {["1", "2", "3", "4", "5"].includes(activeTab) && (
          <Col span={6}>
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              defaultValue={[
                moment(filters.date[0], dateFormat),
                moment(filters.date[1], dateFormat),
              ]}
              onChange={(value, dateString) =>
                setFilters({
                  ...filters,
                  date: dateString ? dateString : [null, null],
                })
              }
              format={dateFormat}
            />
          </Col>
        )}
        {
          <>
            <Col span={4}>
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter by Platform"}
                allowClear
                onChange={(value) =>
                  setFilters({ ...filters, platform: value })
                }
              >
                {["android", "ios", "web", "android_tv", "ios_tv"].map(
                  (code) => (
                    <Select.Option key={code} value={code}>
                      {code}
                    </Select.Option>
                  )
                )}
              </Select>
            </Col>
          </>
        }
        {
          <Col span={4}>
            <Select
              style={{ width: "100%" }}
              placeholder={"User Model "}
              allowClear
              onChange={(value) => setFilters({ ...filters, model: value })}
            >
              {["SUBSCRIPTION", "TICKET"].map((code) => (
                <Select.Option key={code} value={code}>
                  {code}
                </Select.Option>
              ))}
            </Select>
          </Col>
        }
      </Row>
    </>
  );

  // const data1 = [
  //   {
  //     date: "25-06-2022",
  //     organicChurn: 15,
  //     minsOrganic: 1500,
  //     inorganicChurn: 25,
  //     minsInorganic: 2000,
  //     totalMins: 3500,
  //   },
  //   {
  //     date: "26-06-2022",
  //     organicChurn: 15,
  //     minsOrganic: 500,
  //     inorganicChurn: 25,
  //     minsInorganic: 500,
  //     totalMins: 1000,
  //   },
  // ];

  return (
    <div className="manage-orders-wrapper">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={(v) => setActiveTab(v)}
      >
        <TabPane tab="Churn Report" key="1">
          {customfilters}
          <Table
            dataSource={churn}
            pagination={pagination}
            onChange={handleTableDataChange}
          >
            <Column
              title="Organic churn"
              dataIndex="organicChurn"
              key="organicChurn"
              align="center"
            />
            <Column
              title="Mins consumed in a week(B)"
              dataIndex="minsOrganic"
              key="minsOrganic"
              align="center"
            />
            <Column
              title="Inorganic churn"
              dataIndex="inorganicChurn"
              key="inorganicChurn"
              align="center"
            />
            <Column
              title="Mins consumed in a week(D)"
              dataIndex="minsInorganic"
              key="minsInorganic"
              align="center"
            />
            <Column
              title="Total Mins(B+D)"
              dataIndex="totalMins"
              key="totalMins"
              align="center"
            />
          </Table>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ChurnReports;