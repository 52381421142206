import { Layout, Menu, Button } from "antd";

import {
  DatabaseOutlined,
  DesktopOutlined,
  FileOutlined,
  KeyOutlined,
  SettingOutlined,
  StarOutlined,
  UserOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
  TeamOutlined,
  PayCircleOutlined,
} from "@ant-design/icons";

import "./styles.css";
import { useState } from "react";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideNav = ({ updateTab, userEmail }) => {
  const [selectedKeys, setSelectedKeys] = useState([]);

  const manageContentEntries = [
    { key: "manage-movies", text: "Manage Movies" },
    { key: "manage-series", text: "Manage Series" },
    { key: "manage-episodes", text: "Manage Episodes" },
    { key: "manage-songs", text: "Manage Songs" },
  ];

  const masterDataEntries = [
    { key: "manage-artists", text: "Manage Artists" },
    { key: "manage-media-houses", text: "Manage Media Houses" },
    { key: "manage-genres", text: "Manage Genres" },
    { key: "manage-languages", text: "Manage Languages" },
    { key: "manage-plans", text: "Manage Plans" },
    { key: "manage-users", text: "Manage Users" },
  ];

  const featuredContentEntries = [
    { key: "banners", text: "Banners" },
    { key: "playlists", text: "Sections/Playlists" },
  ];

  const ordersTabEntries = [
    { key: "manage-orders", text: "Manage Orders" },
    { key: "manage-tickets", text: "Manage Tickets" },
    { key: "manage-discounts", text: "Manage Discounts" },
  ];

  const reportsTabEntries = [
    { key: "user-reports", text: "User Reports" },
    { key: "churn-reports", text: "Churn Reports" },
    { key: "content-reports", text: "Content Reports" },
    { key: "like-reports", text: "Like Reports" },
  ];

  return (
    <Sider width={255} theme="light">
      <Button
        icon={<VideoCameraAddOutlined />}
        className="add-content-btn"
        onClick={() => {
          updateTab("add-content");
          setSelectedKeys([]);
        }}
      >
        Add Content
      </Button>
      <Button
        icon={<VideoCameraAddOutlined />}
        className="add-content-btn"
        onClick={() => {
          updateTab("upload-content");
          setSelectedKeys([]);
        }}
      >
        Upload Content
      </Button>
      <Menu
        mode="inline"
        defaultSelectedKeys={["dashboard"]}
        selectedKeys={selectedKeys}
        onSelect={(info) => setSelectedKeys(info.selectedKeys)}
        style={{ borderRight: 0 }}
      >
        <Menu.Item
          key="dashboard"
          icon={<DesktopOutlined />}
          onClick={() => updateTab("dashboard")}
        >
          Dashboard
        </Menu.Item>
        <SubMenu
          key="content"
          title="Manage Content"
          icon={<VideoCameraOutlined />}
        >
          {manageContentEntries.map((entry) => (
            <Menu.Item key={entry.key} onClick={() => updateTab(entry.key)}>
              {entry.text}
            </Menu.Item>
          ))}
        </SubMenu>
        <SubMenu key="data" title="Master Data" icon={<DatabaseOutlined />}>
          {masterDataEntries.map((entry) => (
            <Menu.Item key={entry.key} onClick={() => updateTab(entry.key)}>
              {entry.text}
            </Menu.Item>
          ))}
        </SubMenu>
        <SubMenu
          key="featured-content"
          title="Featured Content"
          icon={<StarOutlined />}
        >
          {featuredContentEntries.map((entry) => (
            <Menu.Item key={entry.key} onClick={() => updateTab(entry.key)}>
              {entry.text}
            </Menu.Item>
          ))}
        </SubMenu>
        <SubMenu key="orders" title="Orders" icon={<SettingOutlined />}>
          {ordersTabEntries.map((entry) => (
            <Menu.Item key={entry.key} onClick={() => updateTab(entry.key)}>
              {entry.text}
            </Menu.Item>
          ))}
        </SubMenu>
        <SubMenu key="reports" title="Reports" icon={<PayCircleOutlined />}>
          {reportsTabEntries.map((entry) => (
            <Menu.Item key={entry.key} onClick={() => updateTab(entry.key)}>
              {entry.text}
            </Menu.Item>
          ))}
        </SubMenu>
        <Menu.Item
          key="employees"
          icon={<UserOutlined />}
          onClick={() => updateTab("manage-employees")}
        >
          Manage Employees
        </Menu.Item>
        <Menu.Item
          key="partners"
          icon={<TeamOutlined />}
          onClick={() => updateTab("manage-partners")}
        >
          Manage Partners
        </Menu.Item>
        <Menu.Item
          key="permissions"
          icon={<KeyOutlined />}
          onClick={() => updateTab("manage-permissions")}
        >
          Manage Permissions
        </Menu.Item>
        <Menu.Item
          key="user"
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 1,
            color: "#fff",
            backgroundColor: "#666",
            margin: 0,
          }}
          onClick={() => {}}
        >
          {userEmail}
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideNav;
