import { Form, Input, Modal, Table, Space, Select, notification, Upload, message, Image } from 'antd'
import { useContext, useEffect, useState } from 'react'
import {
  PlusCircleTwoTone,
  ExclamationCircleOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons'

import './styles.css';

import { Context } from '../../context'
import { Fetch, Post, Delete, Put } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'
import UploadBanners from '../UploadBanners/index'

const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

const ManageGenres = () => {
  const { token, apiUrl } = useContext(Context)

  const [genres, setGenres] = useState([])
  const [genreId,setGenreId] = useState()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState("")
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  const [genresMaster, setGenresMaster] = useState([])

  const [form] = Form.useForm()


  useEffect(() => {
    Fetch(`${apiUrl}/genres`, token, (result) => {
      const genres = convertIdToKey(result.genres)
      setGenres(genres)
      setGenresMaster(genres)
    })
  }, [apiUrl, token, counter])

  const showEditModal = (record) => {
    setModalType("Edit")
    setGenreId(record.key)
    form.setFieldsValue(record)
    setModalVisible(true)
  }

  const handleSubmit = async () => {
    try {
      setConfirmLoading(true)
      await form.validateFields()
      const fields = await form.validateFields()
      const fn = modalType === 'Add' ? Post : Put
        fn(
        modalType === 'Add' ? `${apiUrl}/genres` : `${apiUrl}/genres/${genreId}`,
        token,
        fields,
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: modalType === 'Add' ? 'Genre created' : 'Genre Image Updated'
            })
          }
          form.resetFields()
          setModalVisible(false)
          setCounter(counter + 1)
        },
        () => {
          notification.error({
            message: 'Add Genre Error',
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {
      console.error(err)
      notification.error({
        message: 'Add Genre Error',
        description: 'Required fields are incorrect/missing'
      })
    } finally {
      setConfirmLoading(false)
    }
  }

  const confirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this genre?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        Delete(
          `${apiUrl}/genres/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Succes',
                description: 'Genre deleted successfully'
              })
              setCounter(counter + 1)
            }
          },
          () => {
            notification.error({
              message: 'Error deleting genre',
              description: 'Please check console for more details'
            })
          }
        )
      }
    })
  }

  const handleSearchChange = (genreId) => {
    if (!genreId)
      return

    setGenres(genres.filter(g => g.key === genreId))
  }

  return (
    <div className="manage-episodes-wrapper">
      <Select
        placeholder="Search Genre"
        showSearch
        allowClear
        onChange={handleSearchChange}
        onClear={() => setGenres(genresMaster)}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
      >
        {genresMaster.map(g => (
          <Option value={g.key}>{g.name}</Option>
        ))}
      </Select>
      <Table dataSource={genres}>
        <Column title="Name" dataIndex="name" key="name" align="center" />
        <Column title="Card Image" dataIndex={"cardImage"} key="cardImage" align='center'
          render={(_, record) =>
            record.cardImage ?
              <Image src={record.cardImage} width={'200px'} height={'100px'}></Image> : ""
          } />
        <Column
          title="Actions"
          key="actions"
          align="center"
          render={(text, record) => (
            <Space size="middle">
                  <a onClick={() => showEditModal(record)}>Edit</a>
              <a onClick={() => confirmDelete(record.key)}>Delete</a>
            </Space>
          )}
        />
      </Table>

      <PlusCircleTwoTone
        twoToneColor="#FF0091"
        style={{ fontSize: '48px' }}
        className="add-genre-btn"
        onClick={() =>  {
          setModalVisible(true);
          setModalType('Add');
        } }
      />
      <CloudDownloadOutlined
        className="export-btn"
        onClick={() => handleExportClick(apiUrl, token, 'genres')}
      />

      <Modal
        title={ `${modalType}  Genre`}
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => {
          setModalVisible(false);
          form.resetFields(); }}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input disabled={modalType !== 'Add'} />
          </Form.Item> 
          <Form.Item name="cardImage" label="Banner" rules={[{ required: false }]}>
            <UploadBanners name="cardImage" updateForm={form} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ManageGenres;
